import React, { useContext, useEffect, useState } from "react";
import { ClassReservationsContext } from "../context/ClassReservationsContext";
import { AvailableClassesContext } from "../context/AvailableClassesContext";
import { singleClassDateFormat, singleClassTimeFormat } from "../utils";
import { SingleClassContext } from "../context/SingleClassContext";
import MapaLugares from "../components/schedule/MapaLugares";
import { ModalContext } from "../context/ModalContext";
import { AuthContext } from "../context/AuthContext";
import AuthForm from "../components/auth/AuthForm";
import { isFull } from "../utils/single_class";
import { navigate } from "@reach/router";
import ColorLegend from "../components/global/ColorLegend";
import ExpiredCredits from "../components/clases/ExpiredCredits";
import { getLocalMomentObject } from "../utils/dates";

const BookClass = ({ single_class_id }) => {
  const [selectedSpot, setSelectedSpot] = useState(null);

  const { user } = useContext(AuthContext);
  const { postReservacion } = useContext(ClassReservationsContext);
  const { alert, modalComponent } = useContext(ModalContext);
  const { single_class, getSingleClass } = useContext(SingleClassContext);
  const { available_classes, getAvailableClasses } = useContext(
    AvailableClassesContext
  );

  useEffect(() => {
    getAvailableClasses();
    getSingleClass(single_class_id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [single_class_id]);

  const handleBook = () => {
    if (selectedSpot === null) {
      return alert("Debes elegir un lugar en el mapa");
    }
    if (single_class.class_package_id !== null) {
      return navigate(`/checkout/${single_class.class_package_id}`);
    }
    if (!isFull(single_class)) {
      if (user === null) {
        return modalComponent(
          "Debes Acceder para Reservar",
          <AuthForm handleCallback={handleCallback} />,
          {
            no_padding: true,
          }
        );
      }
      if (available_classes?.length < 1 && !user?.has_unlimited) {
        return modalComponent(
          "Créditos Agotados",
          <ExpiredCredits />
        );
      }
    }
    postReservacion(
      {
        single_class_id: single_class.single_class_id,
        spot: selectedSpot,
      },
      handleCallback
    );
  };

  const handleCallback = () => {
    navigate("/schedule");
  };

  const renderClassInfo = () => {
    const classType = single_class?.class_type?.name;
    const instructorName =
      single_class?.class_instructors[0]?.instructor?.nick_name;

    return (
      <h2 className="text-primary mb-0 fw-light">
        {classType}
        <span className="ms-2 text-accent title-font fs-4">
          with {instructorName}
        </span>
      </h2>
    );
  };

  const renderClassDateTime = () => {
    const classDate = getLocalMomentObject(single_class?.class_date).format(
      singleClassDateFormat
    );
    const classTime = getLocalMomentObject(single_class?.class_date)
      .format(singleClassTimeFormat);

    const classTimeEnd = getLocalMomentObject(single_class?.class_date)
      .add(50, "minutes")
      .format(singleClassTimeFormat);

    return (
      <h2 className="text-primary fw-light fs-4">
        {classDate}
        <span className="ms-2 text-accent title-font fs-4">
          {classTime} - {classTimeEnd}
        </span>
      </h2>
    );
  };

  const renderCoachImage = () => {
    let imgSrc = "/assets/coach-profile.png";
    if (single_class?.class_instructors?.length > 0) {
      const coach = single_class?.class_instructors[0]?.instructor;
      if (coach && coach?.file && coach?.file !== null) {
        imgSrc = coach.file.src;
      }
    }
    return (
      <img
        src={imgSrc}
        style={{ width: "80px", height: "80px", borderRadius: "50%" }}
        className="bg-gray px-0 mb-md-5 mb-3"
        alt="coach"
      />
    );
  };

  const renderSpots = () => {
    if (single_class?.class_type?.spot_map) {
      return (
        <MapaLugares
          rows={single_class?.class_type?.spot_map
            .split(",")
            .map((num) => parseInt(num))}
          taken_spots={single_class.taken_spots}
          place={selectedSpot}
          setPlace={setSelectedSpot}
        />
      );
    }
  };

  return (
    <div className="container-fluid book-class px-0 pt-3 bg-dark">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12">
            <div className="row justify-content-center">
              <button
                type={"button"}
                className="btn btn-dark mt-3 text-accent w-max-content fw-light fs-4"
                onClick={() => navigate("/schedule")}
              >
                <i className="fas fa-chevron-left me-3 fs-6" />
                Back
              </button>
            </div>
          </div>

          <div className="col-12 px-0">
            <div className="row justify-content-center">
              <h2
                className="mt-3 px-0 text-accent fw-normal w-max-content"
                style={{ fontSize: "60px" }}
              >
                CHOOSE YOUR SPOT
              </h2>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12 col-md-4 ps-0">
            <div className="row">
              {renderClassInfo()}
              {renderClassDateTime()}
            </div>

            <div className="row mb-5 hide-mobile">
              <div className="d-flex mb-2">
                <div
                  style={{ width: "25px", height: "25px", borderRadius: "50%" }}
                  className="bg-accent"
                ></div>
                <p className="text-accent mb-0 ms-2">Disponible</p>
              </div>

              <div className="d-flex mb-2">
                <div
                  style={{ width: "25px", height: "25px", borderRadius: "50%" }}
                  className="bg-gray border border-2 p-0 border-accent"
                ></div>
                <p className="text-accent mb-0 ms-2">Ocupado</p>
              </div>

              <div className="d-flex">
                <div
                  style={{ width: "25px", height: "25px", borderRadius: "50%" }}
                  className="bg-primary"
                ></div>
                <p className="text-accent mb-0 ms-2">Seleccionado</p>
              </div>
            </div>

            <div className="show-mobile">
              <ColorLegend />
            </div>

            <div className="row hide-mobile">
              <button
                type="button"
                onClick={handleBook}
                className="btn btn-accent text-primary"
                style={{
                  borderRadius: "0px",
                  fontSize: "25px",
                  width: "250px",
                }}
              >
                Reservar Clase
              </button>
            </div>
          </div>

          <div className="col-12 col-md-8 px-md-3 px-0">
            <div className="row justify-content-center">
              {renderCoachImage()}
              <div className="container-fluid text-center">
                <h3 className="text-white">Coach</h3>
              </div>
            </div>
            {renderSpots()}

            <div className="show-mobile">
            <button
                type="button"
                onClick={handleBook}
                className="btn w-100 btn-accent text-primary"
                style={{
                  borderRadius: "0px",
                  fontSize: "25px",
                }}
              >
                Reservar Clase
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookClass;
