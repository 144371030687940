import React from "react";
import Footer from "./Footer";
import Navbar from "./Navbar";

const Page = ({ title, children }) => {
  return (
    <div className="container-fluid px-0">
      <Navbar />
      <div className="container page-content py-5 mt-2">
        <h1 className="mt-5 mb-4 pb-3 border-bottom">{title}</h1>
        {children}
      </div>
      <Footer />
    </div>
  );
};

export default Page;
