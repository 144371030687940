import React, { useContext } from "react";
import { SingleClassContext } from "../../context/SingleClassContext";

const ScheduleSelectDay = ({ prevCategory, nextCategory, todayCategory }) => {
  const { currentDate, setCurrentDate } = useContext(SingleClassContext);

  const dateFormat = "dddd DD / MM";
  const inactiveDaysStyle = {
    fontSize: "12px",
    lineHeight: "13px",
  };

  const handlePrev = () => {
    setCurrentDate(currentDate.clone().subtract(1, "day"));
  };

  const handleNext = () => {
    setCurrentDate(currentDate.clone().add(1, "day"));
  };

  const renderPrevButton = () => {
    return (
      <button className="btn btn-dark text-primary ps-0" onClick={handlePrev}>
        <i className="fa fa-chevron-left text-primary fs-3"></i>
      </button>
    );
  };

  const renderPrevDay = () => {
    const prevDay = currentDate.clone().subtract(1, "day").format(dateFormat);

    return (
      <div className="w-max-content h-max-content">
        <span
          className="mb-0 title-font text-gray fw-normal d-block"
          style={inactiveDaysStyle}
        >
          {prevDay}
        </span>

        <span
          className="mb-0 title-font text-gray fw-normal d-block"
          style={inactiveDaysStyle}
        >
          {prevCategory}
        </span>
      </div>
    );
  };

  const renderNextDay = () => {
    const nextDay = currentDate.clone().add(1, "day").format(dateFormat);

    return (
      <div className="w-max-content h-max-content">
        <span
          className="mb-0 title-font text-gray fw-normal d-block"
          style={inactiveDaysStyle}
        >
          {nextDay}
        </span>

        <span
          className="mb-0 title-font text-gray fw-normal d-block"
          style={inactiveDaysStyle}
        >
          {nextCategory}
        </span>
      </div>
    );
  };

  return (
    <div className="row align-items-center ">
      <div className="d-flex px-0 w-max-content">{renderPrevButton()}</div>

      <div
        className="d-flex justify-content-between align-items-center text-center "
        style={{ flex: 1 }}
      >
        {renderPrevDay()}

        <div className="w-max-content h-max-content">
          <span
            className="mb-0 title-font text-primary fw-normal d-block"
            style={{ fontSize: "15px" }}
          >
            {currentDate.format(dateFormat)}
          </span>
          <span
            className="mb-0 title-font text-primary fw-normal"
            style={{ fontSize: "20px" }}
          >
            {todayCategory}
          </span>
        </div>

        {renderNextDay()}
      </div>

      <div className="d-flex text-right w-max-content px-0">
        <button
          className="btn  btn-dark text-primary pe-0"
          onClick={handleNext}
        >
          <i className="fa fa-chevron-right text-primary fs-3"></i>
        </button>
      </div>
    </div>
  );
};

export default ScheduleSelectDay;
