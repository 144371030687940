import React, { useContext } from "react";
import { ModalContext } from "../../context/ModalContext";
import { getLocalMomentObject } from "../../utils/dates";

const CancelClass = ({ singleClass, reservations, cancelReservacion }) => {
  const { clearModal } = useContext(ModalContext);

  const getClassReservation = () => {
    if (singleClass) {
      if (singleClass.class_reservation_id) {
        return singleClass.class_reservation_id;
      }
    }
    if (reservations && reservations !== null) {
      const class_reservation = reservations.find(
        (reservation) =>
          parseInt(reservation.single_class_id) ===
            parseInt(singleClass.single_class_id) &&
          reservation.deletedAt === null
      );
      if (class_reservation) {
        return class_reservation.class_reservation_id;
      }
    }
  };

  const class_reservation_id = getClassReservation();
  const local_date_time = getLocalMomentObject(singleClass.class_date);

  return (
    <div className="container-fluid px-0">
      <p>
        ¿Deseas cancelar tu clase{" "}
        {singleClass.singleClass
          ? singleClass.singleClass.class_type.name
          : singleClass.class_type.name}{" "}
        del {local_date_time.format("DD MMM YYYY")} a las{" "}
        {local_date_time.format("HH:mm")}
      </p>
      <div className="row">
        <div className="col col-md-6 px-0">
          <button
            className="btn bg-danger text-white"
            onClick={() => cancelReservacion(class_reservation_id)}
          >
            Cancelar Clase
          </button>
        </div>
        <div className="col col-md-6 text-right">
          <button className="btn btn-link text-secondary" onClick={clearModal}>
            Regresar
          </button>
        </div>
      </div>
    </div>
  );
};

export default CancelClass;
