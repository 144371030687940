import React from "react";
import mastercard from "../../assets/images/payment-method-mc.png";
import visa from "../../assets/images/payment-method-visa.png";
import amex from "../../assets/images/payment-method-amex.png";

const PaymentSource = ({
  hideButton,
  paymentMethod,
  payment_source,
  setPaymentMethod,
  cardScale = true,
  handleDelete
}) => {
  const { payment_source_id, card_type, last_digits } = payment_source;

  const handleClick = () => {
    if (typeof setPaymentMethod === "function") {
      setPaymentMethod(payment_source_id);
    }
  };

  const renderDeleteBtn = () => {
    if(typeof handleDelete === 'function') {

      return (
        <button
          type="button"
          className="btn bg-accent text-primary fs-4 cursor-pointer"
          onClick={() => handleDelete(payment_source)}
        >
          Eliminar
        </button>
      )
    }
  }

  return (
    <div
      className={`card shadow p-3 mb-3 ${
        paymentMethod === payment_source_id ? "border-primary" : ""
      } ${cardScale ? '' : 'no-scale'}`}
      onClick={handleClick}
      style={{ cursor: 'default'}}
    >
      <div className="row">
        {!hideButton && (
          <div className="col-1">
            <input type="radio" checked={paymentMethod === payment_source_id} />
          </div>
        )}
        <div className={`${!hideButton ? 'col-11' : 'col-12'}`}>
          <div className="row align-items-center">
            <div className="col">
              <img
                src={
                  card_type === "mastercard"
                    ? mastercard
                    : card_type === "visa"
                    ? visa
                    : amex
                }
                className="card-type"
                alt="card type"
              />
            </div>
            <div className="col capitalize">{card_type}</div>
            <div className="col">
              {"**** "}
              {last_digits}
            </div>
            <div className="col text-end">
              {renderDeleteBtn()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentSource;
