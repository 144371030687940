import React, { useContext, useEffect } from "react";
import Banner from "../components/global/Banner";
import PaqueteCard from "../components/paquetes/PaqueteCard";
import { ClassPackagesContext } from "../context/ClassPackagesContext";

const Paquetes = () => {
  const { class_packages, getClassPackages } = useContext(ClassPackagesContext);

  useEffect(() => {
    getClassPackages();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderPackages = () => {
    if (Array.isArray(class_packages)) {
      return class_packages.map((current) => {
        const headerTitle = current.header ? current.header : null;
        return (
          <div
            key={current.class_package_id}
            className="mb-4 container__card-package"
            style={{ width: "50%" }}
          >
            <PaqueteCard class_package={current} cardHeader={headerTitle} />
          </div>
        );
      });
    }
  };

  return (
    <div className="container-fluid px-0">
      <Banner />
      <div className="row my-5 " style={{ maxWidth: "1250px" }}>
        <div className="col-12">
          <div className="row justify-content-center">
            {renderPackages()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Paquetes;
