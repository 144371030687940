import React from "react";
import LandingClassTypes from "../components/landing/LandingClassTypes";

const ClassTypes = () => {

  return (
    <div className="container-fluid pt-3 px-0 bg-dark" >
      <div className="row justify-content-center mb-4">
        <h2 className="mt-3 text-accent fw-normal w-max-content" style={{ fontSize: "40px" }}>
          CLASSES
        </h2>
      </div>

      <LandingClassTypes/>

    </div>
  );
};

export default ClassTypes;