import React from "react";
import { formatMonto } from "../../utils";
import { getFriendlyLocalDateTimeString, getLocalMomentObject } from "../../utils/dates";

const InvoiceCard = ({ invoice }) => {
  const validClassPackage = () => {
    return invoice.class_package && invoice.class_package !== null;
  };
  const renderNextCharge = () => {
    const { purchase } = invoice;
    if (purchase && purchase !== null) {
      if (purchase.status === "active") {
        const next_invoice = getLocalMomentObject(invoice.createdAt)
          .add(
            invoice.purchase.subscription_interval,
            invoice.purchase.subscription_period
          )
          .format("DD MMM YYYY");
        return (
          <p className="small mb-0">
            <b>Sig. Cargo:</b> {next_invoice}
          </p>
        );
      }
    }
  };

  const renderClassPackage = () => {
    if (validClassPackage()) {
      return invoice.class_package.title;
    }
  };

  return (
    <div className="card px-0 py-0 shadow mb-3">
      <div className="card-header bg-light">
        <h5 className="mb-2">
          #{invoice.invoice_id} - {renderClassPackage()}
        </h5>
      </div>
      <div className="card-body">
        <p className="small mb-0">
          <b>Compra:</b> #{invoice.purchase_id}
        </p>
        <p className="small mb-0">
          <b>Fecha:</b> {getFriendlyLocalDateTimeString(invoice.createdAt)}
        </p>
        <p className="small mb-0">
          <b>Monto:</b> ${formatMonto(invoice.total_payment)} MXN
        </p>
        {renderNextCharge()}
      </div>
    </div>
  );
};
export default InvoiceCard;
