import React, { useContext, useEffect } from "react";
import { ClassReservationsContext } from "../context/ClassReservationsContext";
import { AvailableClassesContext } from "../context/AvailableClassesContext";
import ReservacionRow from "../components/reservaciones/ReservacionRow";
import { Link } from "@reach/router";
import { getLocalMomentObject } from "../utils/dates";

const ClassReservations = ({ filter }) => {
  const { getAvailableClasses } = useContext(AvailableClassesContext);
  const { reservations, getMyReservations } = useContext(
    ClassReservationsContext
  );

  useEffect(() => {
    getMyReservations();
    getAvailableClasses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderReservaciones = () => {
    if (Array.isArray(reservations)) {
      let reservationsRender = [...reservations];
      if (filter === "past") {
        reservationsRender = reservationsRender.filter((reservation) =>
          getLocalMomentObject(reservation.single_class.class_date)
            .diff(getLocalMomentObject(), "hours") + 6 < 0
        );
      } else if (filter === "next") {
        reservationsRender = reservationsRender.filter((reservation) =>
          getLocalMomentObject(reservation.single_class.class_date)
            .diff(getLocalMomentObject(), "hours") + 6 >= 0
        );
      }
      if (reservationsRender.length === 0) {
        return (
          <div className="container-fluid p-3">
            <p>No tienes clases reservadas {filter === "next" ? "próximas": "pasadas"}.</p>
            <Link to="/mybunnation/presencial" className="btn btn-primary">
              Ir a Reservar
            </Link>
          </div>
        );
      }
      return reservationsRender.map((reservation) => (
        <ReservacionRow
          key={reservation.class_reservation_id}
          lightCard={filter === "past"}
          reservacion={reservation}
        />
      ));
    }
    return <div className="spinner-border"></div>;
  };

  return <div className="row">{renderReservaciones()}</div>;
};

export default ClassReservations;
