import React, { useContext, useEffect, useState } from "react";
import { Link } from "@reach/router";
import { ModalContext } from "../context/ModalContext";
import CancelClass from "../components/clases/CancelClass";
import ReservacionRow from "../components/reservaciones/ReservacionRow";
import { AvailableClassesContext } from "../context/AvailableClassesContext";
import { ClassReservationsContext } from "../context/ClassReservationsContext";
import { getLocalMomentObject } from "../utils/dates";

const MisReservaciones = () => {
  const { available_classes, getAvailableClasses } = useContext(AvailableClassesContext);
  const { reservations, getMyReservations, cancelReservacion } = useContext(
    ClassReservationsContext
  );
  const { modalComponent } = useContext(ModalContext);

  const [filtered, setFiltered] = useState(true);

  useEffect(() => {
    getMyReservations();
    getAvailableClasses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const confirmCancel = (reservation) => {
    modalComponent(
      "Cancelar Clase",
      <CancelClass
        single_class={reservation}
        cancelReservacion={cancelReservacion}
      />
    );
  };

  const renderReservaciones = () => {
    if (Array.isArray(reservations)) {
      if (reservations.length === 0)
        return (
          <div className="container-fluid px-0">
            <p>Aún no has reservado clases.</p>
            <Link to="/mybunnation/presencial" className="btn btn-primary">
              Ir a Reservar
            </Link>
          </div>
        );
      let reservationsRender = reservations;
      if (filtered) {
        reservationsRender = reservationsRender.filter((reservation) =>
          getLocalMomentObject(reservation.single_class.class_date)
            .isAfter(getLocalMomentObject().subtract(1, "days"))
        );
      }
      return (
        <>
          <div className="row bg-light border mx-0 py-2 bold hide-mobile">
            <div className="col">Clase</div>
            <div className="col">Coach</div>
            <div className="col">Fecha y Hora</div>
            <div className="col">Reservada en</div>
            <div className="col">Estado</div>
            <div className="col">Acciones</div>
          </div>
          {reservationsRender.map((reservation) => (
            <ReservacionRow
              key={reservation.class_reservation_id}
              reservacion={reservation}
              handleCancel={confirmCancel}
            />
          ))}
        </>
      );
    }
    return <div className="spinner-border"></div>;
  };
  return (
    <div className="container-fluid py-3">
      <div className="row mb-3">
        <div className="col-12 col-md-6 mb-2">
          <h1 className="h2">Mis Reservaciones</h1>
          <p className="mb-0">
            Solo puedes cancelar clases con 6 horas de anticipacion
          </p>
        </div>
        <div className="col-12 col-md-6 mb-2 text-end mobile-left">
          <h4>Restantes: {available_classes?.length}</h4>
          <button
            className="btn btn-outline-primary me-3 mb-2"
            onClick={() => setFiltered(!filtered)}
          >
            {filtered ? "Mostrar" : "Ocultar"} Anteriores
          </button>
          <Link to="/mybunnation/shop" className="btn btn-primary mb-2">
            Comprar Clases
          </Link>
        </div>
      </div>
      {renderReservaciones()}
    </div>
  );
};

export default MisReservaciones;
