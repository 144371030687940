import React from "react";
import { grayImg } from "../../utils";
import { Link } from "@reach/router";

const CoachCard = ({ coach }) => {
  const getSrc = () => {
    if (coach.file && coach.file !== null) {
      return coach.file.src;
    } else {
      return grayImg;
    }
  };

  return (
    <div className="card no-scale justify-content-start bg-white shadow-sm px-0 py-0 text-center w-100 h-100">
      <div className="row w-100 mx-auto position-relative" style={{ flex: 1 }}>
        <img src={getSrc()} className="mw-100 w-100 bg-dark" alt={coach.name} />
        <div className="position-absolute bottom-0 start-0 ms-2">
          <h4
            className="text-accent fw-normal mb-0"
            style={{ width: "max-content" }}
          >
            {coach.name.toLowerCase()}
          </h4>
          <h6
            className="text-accent fw-normal mb-1"
            style={{ width: "max-content" }}
          >
            {coach.music_type}
          </h6>
        </div>
      </div>

      <div className="row w-100 mx-auto" style={{ height: "46px" }}>
        <Link
          to="/schedule"
          className="btn btn-primary rounded-0 border-0 fs-5"
          style={{ width: "75%", fontWeight: 200 }}
        >
          Book a Class
        </Link>
        <a
          target="_blank"
          rel="noreferrer"
          href={coach.instagram}
          className="btn rounded-0 border-0 btn-dark p-0"
          style={{ width: "25%", fontWeight: 200 }}
        >
          <i className="fab fa-instagram fs-3 p-0" />
        </a>
      </div>
    </div>
  );
};

export default CoachCard;
