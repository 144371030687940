import React, { useContext, useState, useEffect } from "react";
import AuthForm from "../auth/AuthForm";
import Waitlist from "../waitlist/Waitlist";
import { Link, navigate } from "@reach/router";
import { AuthContext } from "../../context/AuthContext";
import { ModalContext } from "../../context/ModalContext";
import { getLocalMomentObject } from "../../utils/dates";
import { AvailableClassesContext } from "../../context/AvailableClassesContext";
// import moment from "moment-timezone";

const ScheduleClass = ({ single_class, isHome, isActive, hideButton }) => {
  const [needsLogin, setNeedsLogin] = useState(false);

  const { user } = useContext(AuthContext);
  const { clearModal, modalComponent } = useContext(ModalContext);
  const { available_classes } = useContext(AvailableClassesContext);

  useEffect(() => {
    if (user !== null && needsLogin) {
      clearModal();
      setTimeout(() => {
        setNeedsLogin(false);
        reservarClase();
      }, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const isFull = () => {
    if (single_class !== null) {
      if (single_class.capacity !== null && single_class.class_reservations) {
        return (
          single_class.capacity - single_class.class_reservations.length <= 0
        );
      }
    }
  };

  const handleWaitlist = () => {
    modalComponent(
      "Lista de Espera",
      <Waitlist
        handleCancel={clearModal}
        single_class_id={single_class.single_class_id}
      />
    );
  };

  const reservarClase = () => {
    if (isHome) {
      return navigate(`/checkout/152`);
    }
    if (single_class.class_package_id !== null) {
      return navigate(`/checkout/${single_class.class_package_id}`);
    }
    if (!isFull()) {
      if (user === null) {
        setNeedsLogin(true);
        return modalComponent("Debes Acceder para Reservar", <AuthForm />, {
          no_padding: true,
        });
      }
      if (available_classes?.length > 0) {
        navigate(`/schedule/book/${single_class.single_class_id}`);
      } else {
        modalComponent(
          "Créditos Agotados",
          <div className="container-fluid px-0">
            <p>
              Lo sentimos. Has agotado todos tus créditos para reservar Clases
              Presenciales. Puedes comprar más en Paquetes.
            </p>
            <p className="bold">
              Después de comprar, debes regresar a reservar tu clase.
            </p>
            <Link
              to="/paquetes"
              onClick={clearModal}
              className="btn w-100 btn-primary"
            >
              Ir a Paquetes
            </Link>
          </div>
        );
      }
    }
  };

  const renderBadges = () => {
    const components = [];
    if (isFull()) {
      components.push(
        <div key="spots">
          <span className="badge badge-pill bg-danger">Sold Out</span>
        </div>
      );
    } else {
      let spots =
        single_class.capacity - single_class.class_reservations.length;
      if (spots <= 0) {
        components.push(
          <div key="spots">
            <span className="badge d-inline-block my-1 badge-pill bg-primary-light text-dark">
              Sold out
            </span>
          </div>
        );
      } else if (spots < 4) {
        components.push(
          <div key="spots">
            <span className="badge d-inline-block my-1 badge-pill bg-primary-light text-dark">
              {spots} spot{spots === 1 ? "" : "s"} left
            </span>
          </div>
        );
      }
    }
    if (Array.isArray(single_class.tags)) {
      single_class.tags.forEach((tag) =>
        components.push(
          <div key="spots">
            <span className="badge d-inline-block bg-accent badge-pill my-1">
              {tag.name}
            </span>
          </div>
        )
      );
    }
    return <div className="d-block mb-1">{components}</div>;
  };

  const renderInstructors = () => {
    return single_class.class_instructors.map((class_instructor) => (
      <span className="me-2 title-font" key={class_instructor.instructor_id}>
        {class_instructor.instructor.name}
      </span>
    ));
  };

  const renderClassType = () => {
    if (single_class.class_type !== null) {
      return single_class.class_type.name;
    }
  };

  const renderCoachImg = () => {
    if (isActive) {
      const instructorFile = single_class.class_instructors.find(
        ({ instructor }) => instructor?.file && instructor?.file !== null
      );
      const imgSrc =
        instructorFile && instructorFile !== null
          ? instructorFile.instructor.file.src
          : "/assets/coach-profile.png";
      return (
        <img
          alt="coach"
          src={imgSrc}
          className="bg-dark px-0 me-2"
          style={{ width: "60px", height: "60px", objectFit: "contain" }}
        />
      );
    }
  };

  const renderClassData = () => {
    const classTime = getLocalMomentObject(single_class.class_date);
    const startTime = classTime.clone().format("HH:mm");
    const endTime = classTime.clone().add(50, "minutes").format("HH:mm");
    const coachName = renderInstructors();
    const classType = renderClassType();

    if (isActive) {
      return (
        <div className="d-flex w-max-content h-100 flex-column text-accent text-start">
          <span className="d-block title-font">
            {startTime} - {endTime}
          </span>
          <span className="d-block title-font fw-light">
            {coachName}
            {Array.isArray(coachName) && coachName.length > 0 ? " - " : ""}
            {classType}
          </span>
          <div className="d-block">{renderBadges()}</div>
        </div>
      );
    } else {
      return (
        <div className="d-flex w-max-content h-100 flex-column text-accent text-start">
          <span className="d-block title-font text-light">{startTime}</span>
        </div>
      );
    }
  };

  const renderBookBtn = () => {
    // const now = moment();
    // const class_date = getLocalMomentObject(single_class.class_date);
    // const pastClass = class_date.isBefore(now);

    if (isFull()) {
      return (
        <button
          type="button"
          onClick={handleWaitlist}
          className="schedule-class__btn btn bg-dark text-primary w-max-content fs-2 mt-2"
        >
          Waitlist <i className="far fa-list-alt ms-3" />
        </button>
      );
    }

    if (isActive) {
      return (
        <button
          type="button"
          // disabled={pastClass}
          // style={{ visibility: pastClass ? "hidden" : "visible" }}
          className="schedule-class__btn btn bg-dark text-primary w-max-content fs-2"
          onClick={() => {
            navigate(`/schedule/book/${single_class.single_class_id}`);
          }}
        >
          Book Now <i className="fas fa-chevron-right ms-2" />
        </button>
      );
    }
  };

  return (
    <div
      className={`row justify-content-${hideButton ? "center" : "between"} ${
        isActive ? "schedule-class__active" : ""
      }`}
    >
      {isActive && <div className="col-3">{renderCoachImg()}</div>}
      <div className={isActive ? "col-5" : "container-fluid"}>
        {renderClassData()}
      </div>
      {isActive && !hideButton && (
        <div className="col-4">{renderBookBtn()}</div>
      )}
    </div>
  );
};

export default ScheduleClass;
