import React, { useEffect } from "react";
import { Router } from "@reach/router";
import Home from "./views/Home";
import Login from "./views/Login";
import SignUp from "./views/SignUp";
import Recuperar from "./views/Recuperar";
import Panel from "./views/Panel";
import { useContext } from "react";
import { AuthContext } from "./context/AuthContext";
import Modal from "./components/common/Modal";
import SuccessAlert from "./components/common/SuccessAlert";
import ErrorAlert from "./components/common/ErrorAlert";
import Gracias from "./views/Gracias";
import Loading from "./views/Loading";
import Checkout from "./views/Checkout";
import Paquetes from "./views/Paquetes";
import Terms from "./components/global/Terms";
import PreguntasFrecuentes from "./components/global/PreguntasFrecuentes";
import Ubicacion from "./components/global/Location";
import CoachPage from "./views/pages/CoachPage";
import WhatsApp from "./components/global/WhatsApp";
import { AppConfigContext } from "./context/AppConfigContext";
import { handleColors, handleMetaTags } from "./utils/appconfig";
import Calendario from "./views/Calendario";
import MobileMenu from "./components/common/MobileMenu";
import { SingleClassContext } from "./context/SingleClassContext";
import Navbar from "./components/global/Navbar";
import Footer from "./components/global/Footer";
import Coaches from "./views/Coaches";
import { HomeContext } from "./context/HomeContext";
import BookClass from "./views/BookClass";
import ClassTypes from "./views/ClassTypes";
import { getLocalMomentObject, setupTimezone } from "./utils/dates";

const Main = () => {
  const { user, userLoggedIn } = useContext(AuthContext);
  const appconfig = useContext(AppConfigContext);
  const { setCurrentDate } = useContext(SingleClassContext);
  const { menuItems, setCurrentTab } = useContext(HomeContext);

  useEffect(() => {
    userLoggedIn();
    handleCurrentTab();
    setCurrentDate(getLocalMomentObject());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (appconfig) {
      handleColors(appconfig);
      handleMetaTags(appconfig);
      if (appconfig.timezone && appconfig.timezone !== null) {
        setupTimezone(appconfig.timezone);
      }
    }
  }, [appconfig]);

  const handleCurrentTab = () => {
    const pathname = window.location.pathname;

    menuItems.forEach((item) => {
      if (item.to === pathname) setCurrentTab(item);
    });
  };

  return (
    <div className="main__container container-fluid px-0">
      <MobileMenu />
      <Navbar />
      <Router>
        <Home path="/*" />
        <ClassTypes path="/classes" />
        <Calendario path="/schedule" />
        <BookClass path="/schedule/book/:single_class_id" />
        <Coaches path="/coaches" />
        <Paquetes path="/paquetes" />
        <Login path="/login" />
        <Terms path="/terminos" />
        <SignUp path="/registro" />
        <Ubicacion path="/ubicacion" />
        <Recuperar path="/recuperar" />
        <CoachPage path="/coach/:coach_id" />
        <Gracias path="/gracias/:purchase_id" />
        <Checkout path="/checkout/:class_package_id" />
        <PreguntasFrecuentes path="/preguntas-frecuentes" />
        {user !== null ? (
          <Panel path="mybunnation/*" />
        ) : (
          <Loading path="mybunnation/*" />
        )}
      </Router>
      <WhatsApp />
      <Footer />
      <Modal />
      <SuccessAlert />
      <ErrorAlert />
    </div>
  );
};

export default Main;
