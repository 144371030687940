import React from "react";
import { getFriendlyLocalDateTimeString } from "../../utils/dates";

const StatusBadge = ({ cancelledAt, updatedAt, status }) => {
  return (
    <div>
      {status === "revoked" ? (
        <span className="badge badge-pill bg-danger">Revocada</span>
      ) : status === "cancelled" ? (
        <span className="badge badge-pill bg-danger">
          Cancelada el{" "}
          {getFriendlyLocalDateTimeString(cancelledAt !== null ? cancelledAt : updatedAt)}
        </span>
      ) : status === "active" ? (
        <span className="badge badge-pill bg-success">Activa</span>
      ) : status === "completed" ? (
        <span className="badge badge-pill bg-success">Completada</span>
      ) : status === "pending" ? (
        <span className="badge badge-pull bg-warning text-dark">Pendiente</span>
      ) : status === "failed" ? (
        <span className="badge badge-pull bg-danger text-white">Fallido</span>
      ) : (
        <span className="badge badge-pull bg-light text-capitalize">
          {status}
        </span>
      )}
    </div>
  );
};
export default StatusBadge;
