import React from "react";
import { formatMonto } from "../../utils";
import StatusBadge from "../global/StatusBadge";
import { getFriendlyLocalDateTimeString, getLocalMomentObject } from "../../utils/dates";

const InvoiceRow = ({ invoice }) => {
  const renderClassPackage = () => {
    if (invoice.class_package && invoice.class_package !== null) {
      return invoice.class_package.title;
    }
  };

  const renderNext = () => {
    if (invoice.purchase && invoice.purchase !== null) {
      if (invoice.purchase.status === "active") {
        return getLocalMomentObject(invoice.createdAt)
          .add(
            invoice.purchase.subscription_interval,
            invoice.purchase.subscription_period
          )
          .format("DD MMM YYYY");
      }
    }
  };

  return (
    <tr>
      <td>#{invoice.invoice_id}</td>
      <td>#{invoice.purchase_id}</td>
      <td>{renderClassPackage()}</td>
      <td>
        {"$"}
        {formatMonto(invoice.total_payment)}
        {" MXN"}
      </td>
      <td>{getFriendlyLocalDateTimeString(invoice.createdAt)}</td>
      <td>{renderNext()}</td>
      <td>
        <StatusBadge {...invoice} />
      </td>
    </tr>
  );
};

export default InvoiceRow;
