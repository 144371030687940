import React, { useContext, useEffect } from "react";
import ScheduleDay from "./ScheduleDay";
import ScheduleSelectDay from "./ScheduleSelectDay";
import { SingleClassContext } from "../../context/SingleClassContext";

const MobileSchedule = () => {
  const { getSchedule, getCurrentDateClasses, currentDate, days } = useContext(SingleClassContext);

  useEffect(() => {
    const start_date = currentDate.clone().utc(true).format();
    const end_date = currentDate.clone().utc(true).add(1, 'week').format();

    getSchedule({ start_date, end_date });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDate]);

  const getDayClassCategory = (single_classes) => {
    if (Array.isArray(single_classes)) {
      if (single_classes.length > 0) {
        let current_single_classes = single_classes.sort((a, b) =>
          a.class_date > b.class_date ? 1 : -1
        );
        const firstClass = current_single_classes[0];
        if (firstClass && firstClass !== null) {
          return firstClass.class_type?.name;
        }
      }
    }
  };

  const currentDateClasses = () => {
    if(Array.isArray(days)){
      const formattedDate = currentDate.format("YYYY-MM-DD");
      const classes = getCurrentDateClasses(days, formattedDate);
      return classes;
    }
  }

  const prevDayClasses = getCurrentDateClasses(days, currentDate.subtract(1, "day").format("YYYY-MM-DD"));
  const nextDayClasses = getCurrentDateClasses(days, currentDate.add(1, "day").format("YYYY-MM-DD"));
  const todayClasses = currentDateClasses();

  const prevDayCategory = getDayClassCategory(prevDayClasses);
  const nextDayCategory = getDayClassCategory(nextDayClasses);
  const todayCategory = getDayClassCategory(todayClasses);



  return (
    <div className="container-fluid px-0" style={{ minHeight: '430px'}}>

      <div className="row align-items-center">
        <div className="col-12 col-lg-4 mb-5 px-0">
          <ScheduleSelectDay todayCategory={todayCategory} prevCategory={prevDayCategory} nextCategory={nextDayCategory} />
        </div>
      </div>

      <div className="row px-3">
        <ScheduleDay single_classes={todayClasses}/>
      </div>

    </div>
  );
};

export default MobileSchedule;
