import api from "./api";

const route = "/customer";

const CustomerService = {
  getCurrentCustomer: () => api.get(route),
  getCustomer: (customer_id) => api.get(`${route}/admin/${customer_id}`),
  getCustomerByPhone: (phone) => api.get(`${route}/phone?phone=${phone}`),
  getAllCustomers: (query) =>
    api.get(
      `${route}/admin/all${
        query && query !== null && query !== "" ? `?query=${query}` : ""
      }`
    ),
  getCustomersByQuery: (query) => api.get(`${route}/query?query=${query}`),
  postCustomer: ({ name, email, phone, uid, signup_reason, auth_provider }) =>
    api.post(route, { name, email, phone, uid, signup_reason, auth_provider }),
  putCustomer: (customer_id, name, last_name, phone) =>
    api.put(route, { customer_id, name, last_name, phone }),
  putCurrentCustomer: (customer) => api.put(route, { ...customer }),
  extenderAcceso: (
    customer_id,
    class_package_id,
    package_days,
    is_gift,
    payment_method_id,
    bill
  ) =>
    api.post(`${route}/giveAccess`, {
      customer_id,
      class_package_id,
      package_days,
      is_gift,
      payment_method_id,
      bill,
    }),
  revokeAccess: (purchase_id) =>
    api.put(`${route}/revokeAccess`, { purchase_id }),
  removeClasses: (customer_id, amount) =>
    api.put(`${route}/removeClasses`, { customer_id, amount }),
  recoverPassword: (email) => api.post(`${route}/recover`, { email }),
  signUp: ({ name, email, phone, uid, signup_reason, auth_provider }) =>
    api.post(`${route}/signup`, {
      name,
      email,
      phone,
      uid,
      signup_reason,
      auth_provider,
    }),
};

export default CustomerService;
