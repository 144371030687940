import React from "react";
import { getLocalMomentObject } from "./dates";

export const getStatusReservacionString = (reservacion) => {
  if (reservacion.deletedAt !== null) {
    return "Cancelada";
  }
  if (reservacion.is_cash) {
    if (!reservacion.is_paid) {
      return "Pago Incompletdo";
    }
  }
  if (
    !reservacion.attend &&
    getLocalMomentObject(reservacion.single_class.class_date).isBefore(getLocalMomentObject())
  ) {
    return "No Asistí";
  }
  if (reservacion.attend) {
    return "Exitosa";
  }
  return "Próxima";
};

export const getStatusReservacion = (reservacion) => {
  if (reservacion.deletedAt !== null) {
    return <span className="text-danger">Cancelada</span>;
  }
  if (reservacion.is_cash) {
    if (!reservacion.is_paid) {
      return <span className="text-danger">Pago en Efectivo Incompleto</span>;
    }
  }
  if (
    !reservacion.attend &&
    getLocalMomentObject().isAfter(getLocalMomentObject(reservacion.single_class.class_date))
  ) {
    return <span className="text-warning">No Asistió</span>;
  }
  if (reservacion.attend) {
    return <span className="text-success">Exitosa</span>;
  }
  return <span className="text-secondary">Próxima</span>;
};

export const canCancel = (single_class, cancel_timeframe) => {
  const currentHour = getLocalMomentObject();
  const classHour = getLocalMomentObject(single_class.class_date);
  const diff = classHour.diff(currentHour, "hours") + 5;
  return diff >= cancel_timeframe;
};
