import { Link } from "@reach/router";
import React from "react";

const Banner = ({ overlayLight, disableOverlay }) => {
  const overlayOpacity = disableOverlay ? 1 : 0.8;

  const getOverlayBackground = () => {
    if (disableOverlay) {
      return "";
    } else {
      if (overlayLight) {
        return "bg-accent";
      } else {
        return "bg-dark";
      }
    }
  };

  return (
    <div id="banner" className="row w-100 h-100 mx-auto bg-light">
      <div
        className={`col-12 col-md-7 pt-4 banner__content ${getOverlayBackground()}`}
        style={{ opacity: overlayOpacity }}
      >
        <h3 className="text-primary fs-1 fw-medium px-3 mb-0">JOIN OUR</h3>
        <h1
          className={`content__title fw-normal px-3 ${
            overlayLight ? "text-dark" : "text-accent"
          }`}
        >
          COMMUNITY
        </h1>
        <div className="row w-100 mx-auto px-3 mb-3">
          <div className="content__package col-6 ps-0">
            <p
              className={`mb-0 text-accent  ${
                overlayLight ? "text-dark" : "text-accent"
              }`}
            >
              Paquete <span className="title-font fs-4">25</span> clases
            </p>
            <Link to="/checkout/5" className="title-font text-primary">
              $4,700 <i className="fas fa-chevron-right ms-2" />
            </Link>
          </div>

          <div className="content__package col-6 pe-0">
            <p
              className={`mb-0 text-accent ${
                overlayLight ? "text-dark" : "text-accent"
              }`}
            >
              Paquete <span className="title-font fs-4">1</span> Mes
            </p>
            <Link to="/checkout/5" className="title-font text-primary">
              $3,500 <i className="fas fa-chevron-right ms-2" />
            </Link>
          </div>
        </div>
      </div>
      <div
        className="col-12 col-md-5 bg-image"
        style={{ backgroundImage: `url("https://bunnation.mx/banner.jpg")` }}
      />
    </div>
  );
};
export default Banner;
